import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`8:00 AMRAP of:`}</p>
    <p>{`6-Bar Muscle Ups`}</p>
    <p>{`12-SDHP’s (95/65) (115/75 rx+)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`Sled Push, 5:00 for total distance (down on turf=70ft, Dog sled
+90/45`}{`#`}{`)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no structured bootcamp at 9:30 today but a workout
will be on the board.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      